import { createReducer } from '@reduxjs/toolkit'
import {
  calculate,
  clearPdfFile,
  getParametersOptions,
  getPdfFile,
  resetCalculations,
} from './actions'

const initialState = {
  parametersOptions: null,
  calculations: null,
  pdfFile: null,
  reportIsBeingFormed: false,
  calculationsAreBeingPerformed: false,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getParametersOptions.fulfilled, (state, action) => {
      state.parametersOptions = action.payload
    })
    .addCase(calculate.pending, (state) => {
      state.calculationsAreBeingPerformed = true
    })
    .addCase(calculate.fulfilled, (state, action) => {
      state.calculationsAreBeingPerformed = false
      state.calculations = action.payload
    })
    .addCase(resetCalculations, (state) => {
      state.calculations = null
    })
    .addCase(getPdfFile.pending, (state) => {
      state.reportIsBeingFormed = true
    })
    .addCase(getPdfFile.fulfilled, (state, action) => {
      state.reportIsBeingFormed = false
      state.pdfFile = action.payload
    })
    .addCase(clearPdfFile, (state) => {
      state.pdfFile = null
    })
})

export { reducer }
